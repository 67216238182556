import logo from './logo.svg';
import './App.css';
import {ToastContainer, Zoom} from "react-toastify";
import NotificationFirebase from "./firebaseNotifications/Notification";
import {useEffect} from "react";
import * as serviceWorker from './serviceWorker';

function App() {
    const registerServiceWorker = async () => {
        serviceWorker.register();
    }
    useEffect(() => {
        document.getElementById('acceptNotificationsBtn').addEventListener('click', function() {
            // Check if push notifications are supported and allowed
            if (navigator.serviceWorker && window.PushManager && window.Notification) {
                // Request permission to send push notifications
                // navigator.serviceWorker.getRegistration().then(function(registration) {
                //     registration.pushManager.subscribe({ userVisibleOnly: true }).then(function(subscription) {
                //         console.log('Push notifications are allowed.');
                //         //save the push subscription in your database
                //     }).catch(function(error) {
                //         console.log('Error:', error);
                //     });
                // });
                Notification.requestPermission().then(async function() {
                    await registerServiceWorker()
                });
            }
        });

    }, []);
  return (
      <div className="App">
        <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={true}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            transition={Zoom}
            closeButton={false}
        />
        <NotificationFirebase/>
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
              className="App-link"
              href="https://reactjs.org"
              target="_blank"
              rel="noopener noreferrer"
          >
            Learn React
          </a>
            <button id='acceptNotificationsBtn'>Enable notifications</button>
        </header>
      </div>
  );
}

export default App;
