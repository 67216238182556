// Firebase Cloud Messaging Configuration File.
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyCQzFfbiEtiACwAxfIsRuGvUe7q6mNr5Cg",
    authDomain: "fir-push-test-423614.firebaseapp.com",
    projectId: "firebase-push-test-423614",
    storageBucket: "firebase-push-test-423614.appspot.com",
    messagingSenderId: "1075563663621",
    appId: "1:1075563663621:web:663ee83a767cd3dcf8676c",
    measurementId: "G-K7XLGQRTNJ"
};

initializeApp(firebaseConfig);
const messaging = getMessaging();

export const requestForToken = () => {
    // The method getToken(): Promise<string> allows FCM to use the VAPID key credential
    // when sending message requests to different push services
    return getToken(messaging, { vapidKey: `BJ4xAz1xNEhcwDnNtEfLl-r7z4PHHrWWI8I8TxiRtny23CxOwQthNufunqOu6Y98qjm4WuqRFIoHQgcnQENwTuk` }) //to authorize send requests to supported web push services
        .then((currentToken) => {
            if (currentToken) {
                console.log('current token for client: ', currentToken);

                if(localStorage.getItem('fcmToken') && currentToken !==localStorage.getItem('fcmToken')){
                    localStorage.setItem('fcmToken', currentToken);

                }

                else if(!localStorage.getItem('fcmToken')){
                    localStorage.setItem('fcmToken', currentToken);

                }


            } else {
                console.log('No registration token available. Request permission to generate one.');
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        });
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });


