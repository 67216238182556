import React from "react"

function InfoIcon(props) {

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill={"#85698"}/>
            <path d="M12 16.745C11.59 16.745 11.25 16.405 11.25 15.995V10.995C11.25 10.585 11.59 10.245 12 10.245C12.41 10.245 12.75 10.585 12.75 10.995V15.995C12.75 16.405 12.41 16.745 12 16.745Z" fill={"#85698"}/>
            <path d="M12 9.245C11.87 9.245 11.74 9.215 11.62 9.165C11.5 9.115 11.39 9.045 11.29 8.955C11.2 8.855 11.13 8.755 11.08 8.625C11.03 8.505 11 8.375 11 8.245C11 8.115 11.03 7.985 11.08 7.865C11.13 7.745 11.2 7.635 11.29 7.535C11.39 7.445 11.5 7.375 11.62 7.325C11.86 7.225 12.14 7.225 12.38 7.325C12.5 7.375 12.61 7.445 12.71 7.535C12.8 7.635 12.87 7.745 12.92 7.865C12.97 7.985 13 8.115 13 8.245C13 8.375 12.97 8.505 12.92 8.625C12.87 8.755 12.8 8.855 12.71 8.955C12.61 9.045 12.5 9.115 12.38 9.165C12.26 9.215 12.13 9.245 12 9.245Z" fill={"#85698"}/>
        </svg>
    )
}

export default InfoIcon
